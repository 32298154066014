<template>
  <div :id="anchorId">
    <table
      id="a4"
      v-if="page[index].check || page[index].check == undefined"
      :class="'a4 pg' + terceiro.id"
      width="100%"
      border="0"
    >
      <tbody>
        <tr>
          <td class="td">
            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td valign="top" style="padding-top: 5px">
                    <img :src="logo" alt="logo" style="height: 50px" />
                  </td>
                </tr>
              </tbody>
            </table>
            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    align="center"
                    style="
                      font-size: 18px;
                      padding-top: 10px;
                      padding-bottom: 0px;
                    "
                  >
                    <strong style="font-weight: 600"
                      >AUTORIZAÇÃO DE PAGAMENTO PARA TERCEIROS - ANEXO
                      II</strong
                    >
                  </td>
                </tr>

                <tr>
                  <td
                    align="center"
                    style="font-size: 11px; padding-bottom: 20px"
                  >
                    A {{ contract.company }} lhe dá as boas-vindas e agradece a
                    preferência!
                  </td>
                </tr>
              </tbody>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    align="center"
                    style="
                      background: #e5e5e5;
                      color: #444;
                      font-size: 14px;
                      padding: 5px 10px;
                      font-weight: 600;
                    "
                  >
                    DADOS DO CLIENTE PAGANTE
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              border="0"
              cellspacing="0"
              cellpadding="0"
              style="font-size: 13px"
              width="100%"
            >
              <tr>
                <td
                  colspan="6"
                  style="
                    border-color: #eee;
                    border-width: 0 0 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">NOME:</strong>
                  {{ terceiro.nome }}
                </td>
              </tr>

              <tr>
                <td
                  colspan="2"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">NASCIMENTO:</strong>
                  {{ terceiro.nascimento }}
                </td>
                <td
                  colspan="2"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">CPF:</strong>
                  {{ terceiro.cpf }}
                </td>
                <td
                  width="146"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">RG:</strong>
                  {{ terceiro.rg }}
                </td>
                <td
                  width="219"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">ORGÃO EMISSOR:</strong>
                  {{ terceiro.orgao }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="6"
                  style="
                    border-color: #eee;
                    border-width: 0 0 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">ENDEREÇO:</strong>
                  {{ terceiro.rua }}
                </td>
              </tr>
              <tr>
                <td
                  width="221"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">N°:</strong>
                  {{ terceiro.numero }}
                </td>
                <td
                  colspan="3"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">COMPLEMENTO: </strong>
                  {{ terceiro.complemento }}
                </td>
                <td
                  colspan="2"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">BAIRRO:</strong>
                  {{ terceiro.bairro }}
                </td>
              </tr>
              <tr>
                <td
                  width="221"
                  style="
                    border-color: #eee;
                    border-width: 0 0 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">ESTADO:</strong>
                  {{ terceiro.estado }}
                </td>
                <td
                  colspan="5"
                  style="
                    border-color: #eee;
                    border-width: 0 0 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">CIDADE:</strong>
                  {{ terceiro.cidade }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="3"
                  style="
                    border-color: #eee;
                    border-width: 0 0 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">TELEFONE:</strong>
                  {{ terceiro.telefone }}
                </td>
                <td
                  colspan="3"
                  style="
                    border-color: #eee;
                    border-width: 0 0 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">CELULAR:</strong>
                  {{ terceiro.celular }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="6"
                  style="
                    border-color: #eee;
                    border-width: 0 0 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">E-MAIL:</strong>
                  {{ terceiro.email }}
                </td>
              </tr>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td align="center" style="padding-bottom: 30px"></td>
                </tr>
              </tbody>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    align="center"
                    style="
                      background: #e5e5e5;
                      color: #444;
                      font-size: 14px;
                      padding: 5px 10px;
                      font-weight: 600;
                    "
                  >
                    TERCEIROS BENEFICIÁRIOS
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              width="100%"
              border="0"
              cellspacing="0"
              cellpadding="0"
              style="font-size: 13px; background: #f9f9f9"
            >
              <tr>
                <td
                  width="7%"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <strong style="font-weight: 600">#</strong>
                </td>
                <td
                  width="69%"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">NOME </strong>(Primeiro nome
                  e sobrenome)
                </td>
                <td
                  width="24%"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  <strong style="font-weight: 600">CPF</strong>
                </td>
              </tr>
            </table>

            <table
              width="100%"
              border="0"
              cellspacing="0"
              cellpadding="0"
              style="font-size: 13px"
            >
              <tr v-for="(pessoa, index) in contrato.viajantes" :key="pessoa">
                <td
                  width="7%"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <strong style="font-weight: 600">{{ index + 1 }}</strong>
                </td>
                <td
                  width="69%"
                  style="
                    border-color: #eee;
                    border-width: 0 1px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  {{ pessoa.nome }}
                </td>
                <td
                  width="24%"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px;
                  "
                >
                  {{ pessoa.cpf }}
                </td>
              </tr>

              <tr>
                <td
                  colspan="4"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 0px 0;
                    border-style: solid;
                    padding: 10px 0 5px;
                    font-size: 11px;
                  "
                >
                  <p>
                    Autorizo a
                    {{
                      contract.company_details != undefined
                        ? contract.company_details.company_name +
                          ", CNPJ " +
                          contract.company_details.cnpj
                        : ""
                    }}, em caráter irrevogável e irretratável, a processar a
                    cobrança no meu cartão de crédito conforme Contrato e
                    Autorização de Débito por mim assinados em benefício aos
                    terceiros citados acima.
                  </p>

                  <pre></pre>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TravelContractThirdPartyPayment",
  props: {
    id: Number,
    index: Number,
    key: Number,
    contract: Object,
    contrato: Object,
    terceiro: Object,
    anchorId: String,
    logo: String,
    page: Object,
  },
};
</script>
